import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedDataService } from './shared-data.service';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class ReasonsService {
  private reasonsListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'reasonsList_sequence';

  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  public changesFeed:any 

  constructor(public injector: Injector) {
    this.reasonsListDB = new PouchDB('reasonsList')
    this.reasonsListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');


  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  public async initChanges() {
    const handleChanges = async (change) => {
      //console.log('change',change);
      const sharedDataService = this.injector.get(SharedDataService);
      await this.enqueue(async () => {
        sharedDataService.reasonsDbChanges(change);
      });
      
    };

    // Remove the previous 'change' listener if it exists
    this.reasonsListDB.removeListener('change', handleChanges);

    this.changesFeed = this.reasonsListDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    });
        
    // Add the new 'change' listener
    this.changesFeed.on('change', handleChanges).on('error', (error) => {
      console.error('Error:', error);
    });
    return this.changesFeed; // Return the changes feed instance
  }

  public closeDbOnSync(){
    if(this.changesFeed){
      this.changesFeed.cancel()
    }
  }

  public async use(fc, data,updateByPid=0) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      if(updateByPid == 1){
        return await this.bulkAddOrUpdate(this.reasonsListDB, data, "pid");
      }else{
        return await this.bulkAddOrUpdate(this.reasonsListDB, data, "id");
      }
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
    
      // Query the documents and sort by the "pid" field
      return this.reasonsListDB.find({
        selector: filter,
        //sort: [{ pid: 'asc' }]
      }).then(result => {
        return result.docs.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }
 
  public async getById(id: string) {
    const result = await this.reasonsListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });
    var reasonsToSync = JSON.parse(localStorage.getItem('reasonsToSync'))??[]
    
    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      datatoinsert.id = doc.id;
      datatoinsert.type = doc.type;
      datatoinsert.title = doc.title;
      datatoinsert.created_time = doc.created_time;
      datatoinsert.isSync = doc.isSync??1;

      if(datatoinsert.isSync == 0){
        reasonsToSync.push(datatoinsert.pid)
      }else if(datatoinsert.isSync == 1){
        if(reasonsToSync && reasonsToSync.length > 0){
          reasonsToSync = reasonsToSync.filter(item => item != datatoinsert.pid);
        }
      }
      docsWithIds.push(datatoinsert);
    }

    try {
      localStorage.setItem('reasonsToSync',JSON.stringify(reasonsToSync))
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.reasonsListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.reasonsListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.reasonsListDB.destroy().then(async () => {
      // Create a new database
      this.reasonsListDB = new PouchDB('reasonsList');
      return await this.bulkAddOrUpdate(this.reasonsListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
