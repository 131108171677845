export const appConfig = {

    //stagingadminpos
    // apiUrl: 'https://stagingadminpos.limerr.co/api/v1',
    // mainUrl:'https://stagingadminpos.limerr.co',
    //apiUrlMobile: "https://stagingadminpos.limerr.co/adminpos/api/mobile/v1", // mobile api
    // isLive:0,
    // Mode:'staging',


    // //productionadminpos 
    // apiUrl: "https://adminpos.limerr.co/api/v2",
    // mainUrl:'https://adminpos.limerr.co',
    // apiUrlMobile: "https://adminpos.limerr.co/api/mobile/v1", // mobile api
    // isLive:1,
    // Mode:'Live',



    // kiosk production
    apiUrl: "https://kiosk.limerr.co/adminpos/api/v2", // For New POS
    mainUrl: 'https://kiosk.limerr.co/adminpos',
    apiUrlMobile: "https://kiosk.limerr.co/adminpos/api/mobile/v1", // mobile api
    isLive: 1,
    Mode: 'Limerr',


    // apiUrl: "https://stagingadminpos.limerr.co/api/web/v2", // For pk
    // mainUrl:'https://stagingadminpos.limerr.co',
    //apiUrlMobile: "https://stagingadminpos.limerr.co/adminpos/api/mobile/v1", // mobile api
    // isLive:1,
    // Mode:'Limerr',


    appVersion: "1.1",
    jsonURL: 'http://localhost:3000',
    localUrl: 'http://localhost:9999/',
    buildCreateFor: 'web', // 'web' , 'android' , 'ios'
    devicetype : 0, //0=web, 1=iOS, 2=android
    currency_symbol: localStorage.getItem('currency_symbol'),
    buildFor: 0,   // 0 = POS , 1 = KDS
};