import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { appConfig } from '../app.config';


@Injectable({
  providedIn: 'root'
})
export class PosService {

  constructor(private http: HttpClient) { }

  assignRider(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.mainUrl + '/api/mobile/v1/orderassign', data).subscribe(res => {
        resolve(res);
      });
    });
    // return this.http.post<any>(appConfig.apiUrl + '/orderupdate', data)
    // .pipe(map(res => {
    //   return res;
    // }));
  }

  changestorelanguage(data) {
    return this.http.post<any>(appConfig.apiUrl + '/change-store-language', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllOrderData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderlistsync', data)
      .pipe(map(res => {
        return res;
      }));
  }

  defaultTenderProfile(data) {
    return this.http.post<any>(appConfig.apiUrl + '/set-default-tender-profile', data)
      .pipe(map(res => {
        return res;
      }));
  }

  customerSearch(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customer-search', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getOrderDetail(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderdetail', data)
      .pipe(map(res => {
        return res;
      }));
  }

  paylaterOrderList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/order-list-pay-later', data)
      .pipe(map(res => {
        return res;
      }));
  }

  paylaterCustomerList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/pay-later-customer-search', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updatepaylater(data) {
    return this.http.post<any>(appConfig.apiUrl + '/update-pay-later', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getOrderData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderlist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  countryList() {
    return this.http.post<any>(appConfig.apiUrl + '/allcountrylist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  addInquiry(data) {
    return this.http.post<any>(appConfig.apiUrl + '/businessenquiry', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAttendanceList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/storeuserattendanceget', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllNotification(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getAllNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  clearNotifiction(data) {
    return this.http.post<any>(appConfig.apiUrl + '/clearNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  removeNotification(data) {
    return this.http.post<any>(appConfig.apiUrl + '/deleteNotification', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getDiscount() {
    return this.http.post<any>(appConfig.apiUrl + '/discountlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  placeOrder(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderplace', data)
      .pipe(map(res => {
        return res;
      }));
  }

  orderUpdate(data) {
    return this.http.post<any>(appConfig.apiUrl + '/orderupdate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  ordercancel(data) {
    return this.http.post<any>(appConfig.apiUrl + '/ordercancel', data)
      .pipe(map(res => {
        return res;
      }));
  }

  addTable(data) {
    return this.http.post<any>(appConfig.apiUrl + '/table-update-status', data)
      .pipe(map(res => {
        return res;
      }));
  }

  addReason(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savereason', data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateOrderNumber(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/order-number-update', data)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getStoreProductData(Fdata) {
    return this.http.post<any>(appConfig.apiUrl + '/plumenuitem', Fdata)
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getMainCategoryData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/plucategorylist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenderList() {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', '')
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getTenderTypeList() {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', { department_id: 1 })
      .pipe(map(res => {
        if (res.status == 1) {
          return res.data;
        } else {
          return [];
        }
      }));
  }

  getCustomerList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customerlist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  orderListData(data) {
    return this.http.post<any>(appConfig.apiUrl + '/tendertypelist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  posMenuList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/posmenulist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  tenderListData() {
    return this.http.post<any>(appConfig.apiUrl + '/tenderlist', '')
      .pipe(map(res => {
        return res;
      }));
  }

  customerAddressList(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddresslist', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveAddressForCustomer(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/customeraddressaddupdate', data)
        .subscribe(res => {

          resolve(res);
        });
    });
  }

  removeAddressForCustomer(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddressdelete', data)
      .pipe(map(res => {
        return res;
      }));
  }

  profileForCustomer(data) {
    return this.http.post<any>(appConfig.apiUrl + '/customeraddupdate', data)
      .pipe(map(res => {
        return res;
      }))
  }

  transactionLookupOrder(data) {
    return this.http.post<any>(appConfig.apiUrl + '/transaction_lookup_order', data)
      .pipe(map(res => {
        return res;
      }))
  }

  updateKdsItem(data) {
    return new Promise((resolve, reject) => {
      this.http.post(appConfig.apiUrl + '/update-kds-item', data)
        .subscribe(res => {
          resolve(res);
        });
    });
  }

  getOrderDeliveryAgent(data) {
    return this.http.post<any>(appConfig.apiUrl + '/get_order_delivery_agent', data)
      .pipe(map(res => {
        return res;
      }))
  }

  checkStartOfDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/checkStartofDay', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getstore(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getstore', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveStartofDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savestartofday', data)
      .pipe(map(res => {
        return res;
      }));
  }

  storeReceiptTemplate(data) {
    return this.http.post<any>(appConfig.apiUrl + '/storereceipttemplate', data)
      .pipe(map(res => {
        return res;
      }));
  }

  oneMinCashIn(data) {
    return this.http.post<any>(appConfig.apiUrl + '/saveoneminutecashin', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getkitchenstation() {
    return this.http.post<any>(appConfig.apiUrl + '/getkitchenstation', '')
      .pipe(map(res => {
        return res;
      }));
  }

  newPrinterAdd(data) {
    return this.http.post<any>(appConfig.apiUrl + '/addprinter', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getCashOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getcashout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveNewReason(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savereason', data)
      .pipe(map(res => {
        return res;
      }));
  }

  cashOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savecashout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaidOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/getpaidout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  paidOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/savepaidout', data)
      .pipe(map(res => {
        return res;
      }));
  }

  receiptPrintOut(data) {
    return this.http.post<any>(appConfig.apiUrl + '/receiptendofday', data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveEndofDay(data) {
    return this.http.post<any>(appConfig.apiUrl + '/saveendofday', data)
      .pipe(map(res => {
        return res;
      }));
  }

  checkChildTerminalsClosed(data) {
    return this.http.post<any>(appConfig.apiUrl + '/check-child-terminals-are-closed', data)
      .pipe(map(res => {
        return res;
      }));
  }

}
