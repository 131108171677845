import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedDataService } from './shared-data.service';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class StoreTablesService {
  private storeTablesListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'storeTablesList_sequence';
  private dataTableSubject: BehaviorSubject<any>;
  public dataTable$: Observable<any>;
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  public changesFeed:any

  constructor(private injector: Injector) {
    this.storeTablesListDB = new PouchDB('storeTablesList')
    this.storeTablesListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');

    this.dataTableSubject = new BehaviorSubject<any>(null);
    this.dataTable$ = this.dataTableSubject.asObservable();
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  public async initChanges() {
    // Define the 'change' listener function
    
    const handleChanges = async (change) => {
      const sharedDataService = this.injector.get(SharedDataService);
      await this.enqueue(async () => {
        sharedDataService.tablesDbChanges(change);
      });
      //var currentPage = localStorage.getItem('currentPage') ?? '';
      // if (currentPage == 'tables' || currentPage == 'pos-dashboard' || currentPage == 'end-of-day') {
      //   this.dataTableSubject.next(change);
      // }
    };
    // Remove the previous 'change' listener if it exists
    this.storeTablesListDB.removeListener('change', handleChanges);

    this.changesFeed = this.storeTablesListDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    });
    //changesFeed.cancel(); // whenever you want to cancel    
    // Add the new 'change' listener
    this.changesFeed.on('change', handleChanges).on('error', (error) => {
      console.error('Error:', error);
    });
    return this.changesFeed; // Return the changes feed instance
  }

  public closeDbOnSync(){
    if(this.changesFeed){
      this.changesFeed.cancel()
    }
  }

  public async use(fc, data) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      return await this.bulkAddOrUpdate(this.storeTablesListDB, data, "id");
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  public async getAll(filter) {
    var newfilter: any = {}

    await this.storeTablesListDB.createIndex({
      index: {
        fields: ['pid']
      }
    })
    
    if (!this.isEmpty(filter)) {
      newfilter.selector = filter.filter;
      //Query the documents and sort by the "pid" field
      newfilter.sort = [{ pid: 'desc' }];
      newfilter.skip = filter.skip;
      newfilter.limit = filter.limit;
    } else {
      newfilter.selector = {}
      newfilter.sort = [{ pid: 'desc' }];
    }
    
    newfilter.selector.pid = { "$gte": null } // For sorting . not remove

      // Query the documents and sort by the "pid" field
      return this.storeTablesListDB.find(newfilter).then(result => {
        // result.docs.map(x =>{
        //   if(this.isEmpty(x.order) && x.booking_status == 1){
        //     x.booking_status = 0
        //     x.order_id = 0
        //     x.table_occupied_time = "0"
        //   }
        //   return x
        // })
        return result.docs.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }
 
  public async getById(id: string) {
    const result = await this.storeTablesListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      if(!doc.local_insert_order_number) {
        var randomNumber = Math.floor(10000 + Math.random() * 90000);
        var timestamp = new Date().getTime();
        doc.local_insert_order_number = Number(randomNumber+ '' + timestamp)
      }
      datatoinsert.local_insert_order_number = doc.local_insert_order_number;
      datatoinsert.id = doc.id;
      datatoinsert.unique_id = doc.unique_id;
      datatoinsert.business_id = doc.business_id;
      datatoinsert.state_id = doc.state_id;
      datatoinsert.store_id = doc.store_id;
      datatoinsert.category_id = doc.category_id;
      datatoinsert.title = doc.title;
      datatoinsert.capacity = doc.capacity;
      datatoinsert.location = doc.location;
      datatoinsert.type = doc.type;
      datatoinsert.description = doc.description;
      datatoinsert.booking_status = doc.booking_status;
      datatoinsert.status = doc.status;
      datatoinsert.created_by = doc.created_by;
      datatoinsert.updated_by = doc.updated_by;
      datatoinsert.created_at = doc.created_at;
      datatoinsert.updated_at = doc.updated_at;
      datatoinsert.numberofguest = doc.numberofguest;
      datatoinsert.table_occupied_time = doc.table_occupied_time;
      datatoinsert.order_id = doc.order_id;
      datatoinsert.order_local_transaction_no = doc.order_local_transaction_no??0;
      datatoinsert.merge_tables = doc.merge_tables;
      datatoinsert.unmerge_tables = doc.unmerge_tables;
      const date = new Date();
      let created_at_formated = date.getTime();
      datatoinsert.created_at_formated = created_at_formated;
      if(doc.isMerge == ''){
        doc.isMerge = "0";
      }
      datatoinsert.isMerge = doc.isMerge;
      datatoinsert.isSync = doc.isSync;
      datatoinsert.get_qr = doc.get_qr;

      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.storeTablesListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.storeTablesListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.storeTablesListDB.destroy().then(async () => {
      // Create a new database
      this.storeTablesListDB = new PouchDB('storeTablesList');
      return await this.bulkAddOrUpdate(this.storeTablesListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}

