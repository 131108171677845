import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { Observable, BehaviorSubject } from 'rxjs';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class StorePrintersService {
  private storePrintersListDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'storePrintersList_sequence';
  private dataSubjectPrinter: BehaviorSubject<any>;
  public dataPrinter$: Observable<any>;

  constructor() {
    this.storePrintersListDB = new PouchDB('storePrintersList')
    this.storePrintersListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');
    this.dataSubjectPrinter = new BehaviorSubject<any>(null);
    this.dataPrinter$ = this.dataSubjectPrinter.asObservable();
  }

  public async initChanges() {
    const changesFeed = this.storePrintersListDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    });

    changesFeed.on('change', async (change) => {
      this.dataSubjectPrinter.next(change);
    }).on('error', (error) => {
      console.error('Error:', error);
    });
    return changesFeed; // Return the changes feed instance
  }

  public async use(fc, data, updateByPid = 0) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {

      if (updateByPid == 1) {
        return await this.bulkAddOrUpdate(this.storePrintersListDB, data, "pid");
      } else {
        return await this.bulkAddOrUpdate(this.storePrintersListDB, data, "id");
      }
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  // public async getAll(filter) {
  //   var newfilter:any = {}
  //   if(!this.isEmpty(filter)){
  //     newfilter.selector = filter.filter;
  //    newfilter.skip = filter.skip;
  //    newfilter.limit = filter.limit;
  //   }else{
  //     newfilter.selector = {}
  //   }
  //   // Query the documents and sort by the "pid" field
  //   return this.storePrintersListDB.find(newfilter).then(result => {
  //     return result.docs.sort((a, b) => {
  //       if (a.name < b.name) {
  //         return -1;
  //       } else if (a.name > b.name) {
  //         return 1;
  //       } else {
  //         return 0;
  //       }
  //     });
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }

  public async getAll(filter) {
    var newfilter: any = {};
  
    if (!this.isEmpty(filter)) {
      newfilter.selector = filter.filter;
      newfilter.skip = filter.skip;
      newfilter.limit = filter.limit;
    } else {
      newfilter.selector = {};
    }
    // Query the documents and sort by the "created_at" field
    return this.storePrintersListDB.find(newfilter).then(result => {
      return result.docs.sort((a, b) => {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
  
        // Sort in descending order based on the created_at date
        return dateB - dateA;
      });
    }).catch(error => {
      console.log(error);
    });
  }

  public async getById(id: string) {
    const result = await this.storePrintersListDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }



  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      let currentDate = new Date()
      let created_date = Date.parse(currentDate.toString());

      const date = new Date(created_date);
      let created_at_formated = date.getTime();
      //if needed use below code Nirmal
      // if (element.order_id == 'undefined' || element.order_id == '' || element.order_id == null || element.order_id == 0) {
      //   element.order_id = 0;
      // }
      // if (element.numberofguest == 'undefined' || element.numberofguest == '' || element.numberofguest == null || element.numberofguest == 0) {
      //   element.numberofguest = 0;
      // }

      // if (element.table_occupied_time == null || element.table_occupied_time == '' || element.table_occupied_time == 0 || element.table_occupied_time == 'undefined') {
      //   element.table_occupied_time = '0';
      // }
      // if (element.merge_tables == null || element.merge_tables == '' || element.merge_tables == 0 || element.merge_tables == 'undefined') {
      //   element.merge_tables = '0';
      // }
      // if (element.isMerge == null || element.isMerge == '' || element.isMerge == 0 || element.isMerge == 'undefined') {
      //   element.isMerge = '0';
      // }
      datatoinsert.id = doc.id;
      datatoinsert.business_id = doc.business_id;
      datatoinsert.state_id = doc.state_id;
      datatoinsert.store_id = doc.store_id;
      datatoinsert.name = doc.name;
      datatoinsert.kitchen_department_ids = doc.kitchen_department_ids??"";
      datatoinsert.kitchen_department_store = doc.kitchen_department_store??"";
      datatoinsert.local_computer_host = doc.local_computer_host;
      datatoinsert.local_application_host = doc.local_application_host;
      datatoinsert.model = doc.model??"";
      datatoinsert.description = doc.description??"";
      datatoinsert.connectivity = doc.connectivity;
      datatoinsert.type = doc.type;
      datatoinsert.ip_address = doc.ip_address;
      datatoinsert.port = doc.port;
      datatoinsert.width = doc.width;
      datatoinsert.cash_drawer_attached = doc.cash_drawer_attached??"";
      datatoinsert.language = doc.language??"";
      datatoinsert.is_rtl = doc.is_rtl??"";
      datatoinsert.status = doc.status;
      datatoinsert.created_by = new Date();
      datatoinsert.created_at_formated = created_at_formated;
      datatoinsert.updated_by = new Date();
      datatoinsert.created_at = doc.created_at??"";
      datatoinsert.updated_at = doc.updated_at??"";
      datatoinsert.departments_store_id = doc.departments_store_id??"";
      datatoinsert.kitchen_station_id = doc.kitchen_station_id;
      datatoinsert.printer_type = doc.printer_type??"";
      datatoinsert.connectivity_type = doc.connectivity_type??"";
      datatoinsert.isSync = doc.isSync;
      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.storePrintersListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.storePrintersListDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }



  public async dropAdd(data) {

    return await this.storePrintersListDB.destroy().then(async () => {
      // Create a new database
      this.storePrintersListDB = new PouchDB('storePrintersList');
      return await this.bulkAddOrUpdate(this.storePrintersListDB, data, "id");
    }).then(() => {
      //console.log('Data added to the database.');
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
