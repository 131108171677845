import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class PaidOutService {
  private paidOutDB: any;
  private sequenceDb: any;
  private sequenceDocId = 'paidOut_sequence';

  constructor() {
    this.paidOutDB = new PouchDB('paidOut')
    this.paidOutDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');
  }

  public async use(fc, data,updateByPid=0) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      if(updateByPid == 1){
        return await this.bulkAddOrUpdate(this.paidOutDB, data, "pid");
      }else{
        return await this.bulkAddOrUpdate(this.paidOutDB, data, "id");
      }
    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {
      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }

  public async getAll(filter) {
      // Query the documents and sort by the "pid" field
      return this.paidOutDB.find({
        selector: filter,
        //sort: [{ pid: 'asc' }]
      }).then(result => {
        return result.docs.sort((a, b) => {
          if (a.pid > b.pid) {
            return -1;
          } else if (a.pid < b.pid) {
            return 1;
          } else {
            return 0;
          }
        });
      }).catch(error => {
        console.log(error);
      });
  }
 
  public async getById(id: string) {
    const result = await this.paidOutDB.get(id);
    return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }
  
  
  
  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });

    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });

    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;

      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      if(!doc.local_insert_order_number) {
        var randomNumber = Math.floor(10000 + Math.random() * 90000);
        var timestamp = new Date().getTime();
        doc.local_insert_order_number = Number(randomNumber+ '' + timestamp)
      }
      datatoinsert.local_insert_order_number = doc.local_insert_order_number;
      datatoinsert.id = doc.id;
      datatoinsert.paid_out_reason_localid = doc.paid_out_reason_localid??0;
      datatoinsert.paid_out_reason_id = doc.paid_out_reason_id;
      datatoinsert.clerk_id = doc.clerk_id;
      datatoinsert.amount_type = doc.amount_type;
      datatoinsert.amount = doc.amount;
      datatoinsert.comment = doc.comment;
      datatoinsert.updated_by = doc.updated_by;
      datatoinsert.created_by = doc.created_by;
      datatoinsert.created_at = doc.created_at;
      datatoinsert.created_time = doc.created_time;
      datatoinsert.updated_at = doc.updated_at;
      datatoinsert.clerk_name = doc.clerk_name;
      datatoinsert.isSync = doc.isSync??1;

      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.paidOutDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.paidOutDB.remove(id, doc._rev);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }

  

  public async dropAdd(data) {
    
    return await this.paidOutDB.destroy().then(async () => {
      // Create a new database
      this.paidOutDB = new PouchDB('paidOut');
      return await this.bulkAddOrUpdate(this.paidOutDB, data, "id");
    }).then(() => {
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
