import { Component, NgZone, TemplateRef, ViewChild, EventEmitter, Output, Injector, Inject } from '@angular/core';
import { IonContent, Platform } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token, } from '@capacitor/push-notifications';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { appConfig } from './app.config';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Keyboard } from '@capacitor/keyboard';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './authservices/authentication.service';
import { WebSqlService } from './services/web-sql.service';
import { PassingDataService } from './services/passing-data.service';
import { PosService } from './services/pos.service';
import { StoreTablesService } from './database/store-tables.service';
import { SharedDataService } from './database/shared-data.service';
import { OrdersService } from './database/orders.service';
import { ReasonsService } from './database/reasons.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private initPlugin: boolean;
  modalRef: BsModalRef;
  private spinnerCount = 0;
  private syncspinnerCount = 0;
  private endofdayspinnerCount = 0;
  private endofdayspinnerafterCount = 0;
  currentPage: string;
  isOnline$: Observable<boolean>;
  isPlatform: boolean = false;
  currentNumber = '0';
  textboxname;
  user_id_to_change = 0;
  user: any = {}
  waitForSecondNumber = false;
  private statusSubject = new BehaviorSubject<boolean>(false);
  is_login_with_pin: any = 0;
  permissionList = []
  from = "";
  checkoutList = [];
  userList = []
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  checkDataLiveReloadCalled: any
  checkReasonsDataLiveReloadCalled: any
  checkTableDataLiveReloadCalled: any

  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChild('clockModel') clockModel: TemplateRef<any>;
  @Output() modalResult: EventEmitter<string> = new EventEmitter<string>();

  constructor(private spinner: NgxSpinnerService, private location: Location, private router: Router, private zone: NgZone,
    private modalService: BsModalService, private toastr: ToastrService, private translate: TranslateService, private platform: Platform,
    private authenticationService: AuthenticationService, private injector: Injector, private passData: PassingDataService,
    private _posService: PosService, public storeTablesServiceDb: StoreTablesService, public rasonsServiceDb: ReasonsService, private sharedDataService: SharedDataService, public ordersServiceDb: OrdersService,
    @Inject(DOCUMENT) private document: Document) {
      
      this.platform.ready().then(() => {
      this.platform.resume.subscribe(() => {
        // App was resumed from the background
        console.log('App was resumed from the background');
        // Call your function or perform necessary actions here
        this.callBgOrder();
      });
    });
      
    sharedDataService.setAllData()
    let browserLang = localStorage.getItem('language');
    let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    this.translate.use(browserLang);
    if (browserLang == "arabic") {
      htmlTag.dir = 'rtl';
    } else {
      htmlTag.dir = 'ltr';
    }
    this.startDBChanges();
  }

  stopDBChanges() {
    this.checkDataLiveReloadCalled = null
    this.checkTableDataLiveReloadCalled = null
    this.checkReasonsDataLiveReloadCalled = null
  }


  async notificationSyncBgOrder() {
    if (appConfig.buildCreateFor == "web") {
      await this.listenForMessages();
    }

    if (appConfig.buildCreateFor != "web") {
      var checkisStartofDay = localStorage.getItem('isStartofDay');
      if (checkisStartofDay == 'true') {
        this.platform.ready().then(() => {
          // Check if the app was just launched
          if (this.platform.ready()) {
            // App was closed and reopened
            console.log('App was closed and reopened.');
  
            // Call your function or perform necessary actions here
            this.callBgOrder();
          }
  
          // Subscribe to the resume event for cases when the app is resumed from the background
          // this.platform.resume.subscribe(() => {
          //   // App was resumed from the background
          //   console.log('App was resumed from the background.');
  
          //   // Call your function or perform necessary actions here
          //   this.callBgOrder();
          // });
        });
      }
      await this.pushnotificationApp();
    }
  }

  startDBChanges() {
    if (!this.checkDataLiveReloadCalled) {
      this.checkDataLiveReloadCalled = this.ordersServiceDb.initChanges();
    }
    if (!this.checkTableDataLiveReloadCalled) {
      this.checkTableDataLiveReloadCalled = this.storeTablesServiceDb.initChanges();
    }
    if (!this.checkReasonsDataLiveReloadCalled) {
      this.checkReasonsDataLiveReloadCalled = this.rasonsServiceDb.initChanges();
    }
  }

  async spinnerActivate(checkspinner = 'spinner') {
    if (checkspinner == "spinner") {
      this.spinnerCount++;
    } else if (checkspinner == "syncspinner") {
      this.syncspinnerCount++;
    } else if (checkspinner == "endofdayspinner") {
      this.endofdayspinnerCount++;
    } else if (checkspinner == "endofdayspinnerafter") {
      this.endofdayspinnerafterCount++;
    }
    this.spinner.show(checkspinner);
  }

  async spinnerDeactivate(checkspinner = 'spinner') {

    if (checkspinner == "spinner") {
      if (this.spinnerCount > 0) {
        this.spinnerCount--;
        if (this.spinnerCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
    if (checkspinner == "syncspinner") {
      if (this.syncspinnerCount > 0) {
        this.syncspinnerCount--;
        if (this.syncspinnerCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
    if (checkspinner == "endofdayspinner") {
      if (this.endofdayspinnerCount > 0) {
        this.endofdayspinnerCount--;
        if (this.endofdayspinnerCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
    if (checkspinner == "endofdayspinnerafter") {
      if (this.endofdayspinnerafterCount > 0) {
        this.endofdayspinnerafterCount--;
        if (this.endofdayspinnerafterCount == 0) {
          this.spinner.hide(checkspinner);
        }
      }
    }
  }

  async ngOnInit() {
    this.notificationSyncBgOrder()
  }
  
  callBgOrder() {
    PushNotifications.getDeliveredNotifications().then(x => {
      if (x.notifications.length > 0) {
        PushNotifications.removeAllDeliveredNotifications();
        const sqlService = this.injector.get(WebSqlService);
        sqlService.syncBGOrder()
      }
    })
  }

  // ionViewWillLeave() {
  //   if (this.dataSubscription) {
  //     this.dataSubscription.unsubscribe();
  //   }
  // }

  ngAfterViewInit() {
    if (appConfig.buildCreateFor !== "web") {
      Keyboard.addListener('keyboardWillShow', () => {
        (this.content as any).scrollToPoint(0, 200, 300);
      });
    
      Keyboard.addListener('keyboardWillHide', () => {
        (this.content as any).scrollToPoint(0, 0, 300);
      });
    }
  }

  pushnotificationApp() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        localStorage.setItem('fcm_token', '0')
        // Show some error
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error))
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        //console.log('Push received: ' + JSON.stringify(notification))
        await this.enqueue(async () => {
          await this.notificationget(notification);
        });
      }
    );

    //Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification))
        const sqlService = this.injector.get(WebSqlService);
        sqlService.syncBGOrder()
      
        // var obj = {
        //   "data": notification.notification.data,
        //   "title": notification.notification.data.title,
        //   "body": notification.notification.data.body
        // }
        // await this.enqueue(async () => {
        //   await this.notificationget(obj);
        // });
      }
    );

    // PushNotifications.addListener('pushNotificationActionPerformed',
    //   async (notification: ActionPerformed) => {
    //     console.log('Push action performed: ' + JSON.stringify(notification))
    //     // this.callBgOrder()
    //   }
    // );

    PushNotifications.createChannel({
      id: 'background-channel',
      name: 'Background Notifications',
      description: 'Channel for background notifications',
      importance: 1, // Adjust importance level as needed
      visibility: 1, // Visibility options
    });


  }

  listenForMessages() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.addEventListener('message', async (event) => {
        var notificationData = event.data
        await this.enqueue(async () => {
          await this.notificationget(notificationData);
        });
      })
    }
  }

  async notificationget(notificationData) {
    var checkisStartofDay = localStorage.getItem('isStartofDay');
    if (notificationData.data && checkisStartofDay == 'true') {
      //console.log('notificationData---', notificationData)
      if (notificationData.data.order_status == 3) {
        //console.log('notificationData.data.order_status',notificationData.data.order_status);
        let audio = new Audio("../assets/pending_orders_sound.mp3");
        audio.play()
        this.passData.defaultRingtone(audio)
      } else {
        //console.log('notificationData.data.order_status',notificationData.data.order_status);
        let audio = new Audio("../assets/new.mp3");
        audio.play();
      }
      console.log('notificationData',notificationData);
      if (notificationData.data.click_action != "table_updated") {
        if (appConfig.buildCreateFor == "web") {
          this.toastr.success(notificationData?.notification?.title, '');
        } else {
          this.toastr.success(notificationData.title, '');
        }
        const sqlService = this.injector.get(WebSqlService);
        return await this._posService.getOrderDetail({ "order_id": notificationData.data.order_id }).subscribe({
          next: async (res) => {
            if (res.status == 1) {
              await this.enqueue(async () => {
                /* await */ sqlService.insertUpdateOrder(res.data, 0, 0, 0, 1);
              });
            } else {
              console.log("error:", res.status);
            }
          },
          error: (error) => {
            console.log(error);
          }
        });
      } else if (notificationData.data.click_action == "table_updated") {
          var table = JSON.parse(notificationData.data.data);
          var merge_tables = table.merge_tables ?? [];
          var unmerge_tables = table.unmerge_tables ?? [];
        
          // Handle main table update
          if (table) {
            var tables = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: table.id } });
        
            if (tables && tables.length > 0) {
              table.pid = tables[0].pid;
              table.isSync = 1;
        
              console.log('Updating main table:', table);
              this.storeTablesServiceDb.use('IU_DATA', [table]);
            } else {
              console.log('Main table not found. Inserting new table:', table);
              this.storeTablesServiceDb.use('IU_DATA', [table]);
            }
          }
        
          // Handle merge tables
          if (merge_tables.length > 0) {
            merge_tables = merge_tables.split(',');
        
            for (var i = 0; i < merge_tables.length; i++) {
              let merge_table_id = Number(merge_tables[i]);
              var mergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: merge_table_id } });
        
              if (mergeTable && mergeTable.length > 0) {
                let mergedTable = mergeTable[0];
                mergedTable.pid = table.pid; // Assuming you want to use the main table's pid
                mergedTable.isSync = 1;
                mergedTable.isMerge = table.id; // Assuming you want to reference the main table's id
                console.log('Updating merged table:', mergedTable);
                this.storeTablesServiceDb.use('IU_DATA', [mergedTable]);
              }
            }
          }
          if (unmerge_tables.length > 0) {
            unmerge_tables = unmerge_tables.split(',');
            for (var i = 0; i < unmerge_tables.length; i++) {
              let unmerge_table_id = Number(unmerge_tables[i]);
              var unmergeTable = await this.storeTablesServiceDb.use('GA_DATA', { filter: { id: unmerge_table_id } });
              if (unmergeTable && unmergeTable.length > 0) {
                let unmergedTable = unmergeTable[0];
                unmergedTable.pid = table.pid; // Assuming you want to use the main table's pid
                unmergedTable.isSync = 1;
                unmergedTable.isMerge = 0; // Assuming you want to reference the main table's id
                unmergedTable.merge_tables = 0
                console.log('Updating unmergedTable table:', unmergedTable);
                this.storeTablesServiceDb.use('IU_DATA', [unmergedTable]);
              }
            }
          }
        }
  
      // } else if (notificationData.data.click_action == "table_updated") {
      //   var table = JSON.parse(notificationData.data.data)
      //   console.log('table',table);
      //   var tables = await this.storeTablesServiceDb.use('GA_DATA', { filter:{id: table.id} })
      //   if (tables && tables.length > 0) {
      //     table.pid = tables[0].pid
      //     table.isSync = 1
      //     console.log('if');
      //     this.storeTablesServiceDb.use('IU_DATA', [table])
      //   } else {
      //     console.log('else');
      //     this.storeTablesServiceDb.use('IU_DATA', [table])
      //   }
      // }
    }

    return 0;
  }

  clockIn(from = '', user_id_to_change = 0): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.onfocus("newusernameclockin");
      this.is_login_with_pin = localStorage.getItem('is_login_with_pin')
      this.from = from;
      this.user_id_to_change = user_id_to_change;
      if (from == "firstClockIn") {
        this.is_login_with_pin = 0;

      }
      this.user.newusernameclockin = "";
      this.user.newuserpasswordclockin = "";
      this.modalRef = this.modalService.show(
        this.clockModel, {
        ignoreBackdropClick: true,
      }
      );
      this.modalRef.setClass('modal-dialog-centered modal-lg');

      // Resolve or reject the promise based on the modal result
      this.modalRef.onHide.subscribe(() => {
        if (localStorage.getItem('clockInModelClose') == "SUCCESS") {
          localStorage.removeItem('clockInModelClose')
          resolve(true);
        } else {
          localStorage.removeItem('clockInModelClose')
          resolve(false);
        }
      });
    });
  }

  // Handle the form submission
  clockInSubmit() {
    this.user.store_id = localStorage.getItem('store_id');
    this.user.username = this.user.newusernameclockin
    this.user.password = this.user.newuserpasswordclockin
    delete this.user.newusernameclockin
    delete this.user.newuserpasswordclockin
    if (this.from == "firstClockIn") {  // First Time Clock IN Code 
      this.spinnerActivate()
      let checkinList = JSON.parse(localStorage.getItem("checkinUserList"))
      if (checkinList) {
        var ischekIn = checkinList.find(x => Number(x.username) == Number(this.user.username))
      }

      if (!ischekIn) {
        this.authenticationService.login(this.user).subscribe({
          next: async (data) => {
            if (data.status == 1) {

              var user = data.data;
              var user_id = user.id

              user.system_date = new Date();
              this.user.checkInTime = user.system_date
              user.userDetail = this.user;

              // add into local storage
              if (localStorage.getItem('checkinUserList')) {
                let existsUserList = JSON.parse(localStorage.getItem('checkinUserList'));
                this.userList = existsUserList;
                let checkExist = existsUserList.find(x => x.id === user.id)
                if (!checkExist) {
                  this.userList.push(user)
                  localStorage.setItem('checkinUserList', JSON.stringify(this.userList));
                }
              } else {
                this.userList.push(user)
                localStorage.setItem('checkinUserList', JSON.stringify(this.userList));
              }
              var role_obj = user.roles[0];
              this.permissionList = []
              role_obj.permissions.forEach(element => {
                this.permissionList.push(element.name)
              });

              if (localStorage.getItem('permissions')) {
                localStorage.removeItem('permissions');
                localStorage.setItem('permissions', JSON.stringify(this.permissionList))
              } else {
                localStorage.setItem('permissions', JSON.stringify(this.permissionList))
              }

              this.toastr.success('', data.message);
              const sqlService = this.injector.get(WebSqlService);
              if (localStorage.getItem("firstTimeDataCall") != "1") {
                localStorage.setItem("firstTimeDataCall", "1");
                await sqlService.getstorePosBehavior(1);
                //await sqlService.posMenuList();
                //sqlService.getPrinterList()
              }
              sqlService.setCheckAttendance(user_id);
              localStorage.setItem('clockInModelClose', "SUCCESS") // After Model Close And Response Positive
              this.passData.defaultUserName("");
              this.modalRef.hide();
              this.spinnerDeactivate();
            } else {
              this.spinnerDeactivate();
              this.toastr.error('', data.message);
              this.modalRef.hide();
            }
          },
          error: (error) => {
            if (error.status == 401) {
              this.toastr.error('', this.translate.instant('Please enter valid credentials of staff.'));
            } else if (error.status == 0 || error.status == 504) { //Http failure response // 504 Gateway Timeout
              this.toastr.warning('', this.translate.instant('You are offline! Please check your internet connection.'));
            } else if (error.status == 404) {
              this.toastr.error('', this.translate.instant('Failed to connect, please contact to limerr support.'));
            }
            this.spinnerDeactivate();
            this.modalRef.hide();
          }
        });
      } else {
        this.spinnerDeactivate();
        this.toastr.warning(this.translate.instant("You are already clocked in."))
        this.modalRef.hide();
      }

    } else if (this.from == "clockOut") {  // ClockOut Code
      this.spinnerActivate()
      let checkinList = JSON.parse(localStorage.getItem("checkinUserList"))
      var checkLogin = 0
      if (checkinList) {
        if (this.is_login_with_pin == 0) {
          var ischekIn = checkinList.find(x => Number(x.username) == Number(this.user.username))
          checkLogin = 1
        } else {
          var ischekIn = checkinList.find(x => Number(x.pin) == Number(this.user.username))
          checkLogin = 1
          this.user.username = ischekIn.username
          this.user.password = ischekIn.userDetail.password
        }
      }
      if (ischekIn) {
        this.authenticationService.login(this.user).subscribe({
          next: async (data) => {
            if (data.status == 1) {

              let user = data.data;
              var user_id = user.id
              const sqlService = this.injector.get(WebSqlService);
              sqlService.setCheckAttendanceClockout(user_id);
              user.system_date = new Date();
              this.user.checkOutTime = user.system_date
              user.userDetail = this.user;

              // remove check in user after checkout
              let findCheckIn = checkinList.find(x => x.id === user.id)
              let index = checkinList.findIndex(x => x.id === user.id);
              if (findCheckIn) {
                this.userList.splice(index, 1)
              }

              if (this.userList.length == 0) {
                localStorage.removeItem('checkinUserList');
                localStorage.removeItem('permissions');
                localStorage.removeItem('token');
                localStorage.removeItem('currentUser');
                this.passData.defaultUserName("");
              } else {
                localStorage.setItem('checkinUserList', JSON.stringify(this.userList))
              }

              if (localStorage.getItem('checkinUserList')) {
                var checkinusers = JSON.parse(localStorage.getItem('checkinUserList'));
                var checkinusers_length = checkinusers.length;
                if (checkinusers_length > 0) {
                  var role_obj = checkinusers[checkinusers_length - 1].roles[0];
                  localStorage.setItem('currentUser', JSON.stringify(checkinusers[checkinusers_length - 1]))
                  this.passData.defaultUserName(JSON.stringify(checkinusers[checkinusers_length - 1].username));
                  this.permissionList = []
                  role_obj.permissions.forEach(element => {
                    this.permissionList.push(element.name)
                  });

                  if (localStorage.getItem('permissions')) {
                    localStorage.removeItem('permissions');
                    localStorage.setItem('permissions', JSON.stringify(this.permissionList))
                  }
                } else {
                  localStorage.removeItem('permissions');
                  this.passData.defaultUserName("");
                }
              } else {
                localStorage.removeItem('permissions');
                this.passData.defaultUserName("");

              }
              localStorage.setItem('clockInModelClose', "SUCCESS") // After Model Close And Response Positive
              this.passData.defaultUserName("");
              this.modalRef.hide();
              this.spinnerDeactivate();
            } else {
              this.spinnerDeactivate();
              this.toastr.error('', data.message);
              this.modalRef.hide();
            }
          },
          error: (error) => {
            if (error.status == 401) {
              this.toastr.error('', this.translate.instant('Please enter valid credentials of staff.'));
            } else if (error.status == 0 || error.status == 504) { //Http failure response // 504 Gateway Timeout
              this.toastr.warning('', this.translate.instant('You are offline! Please check your internet connection.'));
            } else if (error.status == 404) {
              this.toastr.error('', this.translate.instant('Failed to connect, please contact to limerr support.'));
            }
            this.spinnerDeactivate();
            this.modalRef.hide();
          }
        });
      } else {
        this.spinner.hide()
        this.spinnerDeactivate();
        this.toastr.warning(this.translate.instant("You haven't clocked In yet. Would you like to clock in now?"))
      }
    } else { // Local Clock IN Code
      let checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
      var checkLogin = 0
      var localuser
      if (checkinUser) {
        if (this.is_login_with_pin == 0) {
          localuser = checkinUser.find(x => Number(x.userDetail.username) === Number(this.user.username))
          if ((localuser) && Number(localuser.userDetail.username) === Number(this.user.username) && localuser.userDetail.password === this.user.password
            && localuser.userDetail.store_id === this.user.store_id) {
            checkLogin = 1
          }
        } else {
          localuser = checkinUser.find(x => Number(x.pin) === Number(this.user.username))
          checkLogin = 1
        }
        if (localuser) {
          var checkChangeUser = 1;
          if (this.from == "change_user" && localuser.id != this.user_id_to_change) {
            checkChangeUser = 0;
          }
          if (checkLogin == 1 && checkChangeUser == 1) {
            localStorage.setItem('currentUser', JSON.stringify(localuser))
            localStorage.setItem('token', JSON.stringify(localuser.user_token))

            var role_obj = localuser.roles[0];
            this.permissionList = []
            role_obj.permissions.forEach(element => {
              this.permissionList.push(element.name)
            });
            localStorage.setItem('permissions', JSON.stringify(this.permissionList))
            localStorage.setItem('clockInModelClose', "SUCCESS") // After Model Close And Response Positive
            this.passData.defaultUserName("");
            this.modalRef.hide();
          } else {
            this.toastr.error(this.translate.instant("Username or Password not match"))
            this.modalRef.hide();
          }
        } else {
          this.toastr.error(this.translate.instant("You haven't check in. Please check in first"))
          this.modalRef.hide();
        }
      } else {
        this.toastr.error(this.translate.instant("You haven't check in. Please check in first"))
        this.modalRef.hide();
      }
    }
  }

  onfocus(name) {
    var tempData = localStorage.getItem('platform')
    if (tempData == 'web') {
      this.isPlatform = false;
    } else {
      this.isPlatform = true;
    }
    this.currentNumber = "0"
    this.textboxname = name;
  }

  public getNumber(v: string) {

    if (this.textboxname == "newusernameclockin") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.newusernameclockin = this.currentNumber
      }
    }
    if (this.textboxname == "newuserpasswordclockin") {
      if (this.waitForSecondNumber) {
        this.currentNumber = v;
        this.waitForSecondNumber = false;
      } else {
        this.currentNumber === '0' ? this.currentNumber = v : this.currentNumber += v;
        this.user.newuserpasswordclockin = this.currentNumber
      }
    }

  }

  remove() {
    if (this.textboxname == "newusernameclockin") {
      this.currentNumber = this.user.newusernameclockin.toString().slice(0, -1)
      this.user.newusernameclockin = this.currentNumber
    }
    if (this.textboxname == "newuserpasswordclockin") {
      this.currentNumber = this.user.newuserpasswordclockin.toString().slice(0, -1)
      this.user.newuserpasswordclockin = this.currentNumber
    }
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

}
