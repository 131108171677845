import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';
import pouchdbFind from 'pouchdb-find';
import { Observable, BehaviorSubject } from 'rxjs';
import { TenderProfilesStoreService } from './tender-profiles-store.service';
import { DataServicesService } from '../services/data-services.service';
import { OrderDetailService } from './order-detail.service';

import { StoreTablesService } from './store-tables.service';
import { SharedDataService } from './shared-data.service';

PouchDB.plugin(pouchdbFind);


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private ordersServiceListDB: any;
  private DbChanges: any;
  private sequenceDb: any;
  private tablesMainList: any = [];
  private sequenceDocId = 'ordersServiceList_sequence';
  private dataSubject: BehaviorSubject<any>;
  public data$: Observable<any>;
  private queue: (() => Promise<any>)[] = [];
  private isProcessing: boolean = false;
  public changesFeed:any 

  constructor(public tenderProfilesStoreServiceDb: TenderProfilesStoreService, public orderDetailServiceDb: OrderDetailService, public injector: Injector,public storeTablesServiceDb: StoreTablesService) {
    this.ordersServiceListDB = new PouchDB('ordersServiceList')

    this.ordersServiceListDB.createIndex({
      index: {
        fields: ['pid', 'id']
      }
    })
    this.sequenceDb = new PouchDB('mydb_sequence');

    this.dataSubject = new BehaviorSubject<any>(null);
    this.data$ = this.dataSubject.asObservable();
    
  }

  async enqueue(fn: () => Promise<any>) {
    this.queue.push(fn);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue() {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const fn = this.queue[0];
      try {
        const result = await fn();
      } catch (error) {
        console.error(error); // Handle the error as needed
      }
      this.queue.shift();
    }
    this.isProcessing = false;
  }

  public async initChanges() {
    const handleChanges = async (change) => {
      const sharedDataService = this.injector.get(SharedDataService);
      await this.enqueue(async () => {
        sharedDataService.orderDbChanges(change);
      });
      //var currentPage = localStorage.getItem('currentPage') ?? '';
      // if (currentPage == 'tables' || currentPage == 'pos-dashboard' || currentPage == 'end-of-day') {
      //   this.dataSubject.next(change);
      // }
    };

    // Remove the previous 'change' listener if it exists
    this.ordersServiceListDB.removeListener('change', handleChanges);

    this.changesFeed = this.ordersServiceListDB.changes({
      live: true,
      since: 'now',
      include_docs: true
    });
        
    // Add the new 'change' listener
    this.changesFeed.on('change', handleChanges).on('error', (error) => {
      console.error('Error:', error);
    });
    //changesFeed.cancel(); // whenever you want to cancel
    return this.changesFeed; // Return the changes feed instance
  }

  public closeDbOnSync(){
    if(this.changesFeed){
      this.changesFeed.cancel()
    }
  }


  public async use(fc, data, updateByPid = 0) {
    if (fc == "G_DATA") {
      return this.getById(data._id)
    } else if (fc == "GA_DATA") {
      return this.getAll(data)
    } else if (fc == "IU_DATA") {
      if (updateByPid == 1) {
        return await this.bulkAddOrUpdate(this.ordersServiceListDB, data, "pid");
      } else {
        return await this.bulkAddOrUpdate(this.ordersServiceListDB, data, "id");
      }

    } else if (fc == "D_DATA") {
      return this.delete(data.id)
    } else if (fc == "DROP_ADD") {

      return await this.dropAdd(data);
    }
  }

  //selector === Equality operator $eq,Greater than operator $gt,Greater than or equal to operator $gte,Less than operator $lt,Less than or equal to operator $lte,In       operator $in,Not equal operator $ne,Not in operator $nin,Exists operator $exists,Type operator $type,Regex operator $regex// ex :name: { $eq: 'John' }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  public async getAll(filter) {
    
    var newfilter: any = {}

    await this.ordersServiceListDB.createIndex({
      index: {
        fields: ['local_transaction_no']
      }
    })

    if (!this.isEmpty(filter)) {
      newfilter.selector = filter.filter;
      //Query the documents and sort by the "pid" field
      newfilter.sort = [{ local_transaction_no: 'desc' }];
      newfilter.skip = filter.skip;
      newfilter.limit = filter.limit;
    } else {
      newfilter.selector = {}
      newfilter.sort = [{ local_transaction_no: 'desc' }];
    }
    newfilter.selector.local_transaction_no = { "$gte": null } // For sorting . not remove
    
    //var tenderProfilesStoreService = await this.tenderProfilesStoreServiceDb.use('GA_DATA', {})
    const sharedDataService = this.injector.get(SharedDataService);
    const tenderProfilesStoreService = JSON.parse(JSON.stringify(await sharedDataService.getTenderProfilesListArray()));
    return this.ordersServiceListDB.find(newfilter).then(async (result) => {
      const repeat_local_id = [];
      const local_id_array = [];
      this.tablesMainList = await this.storeTablesServiceDb.use('GA_DATA', {})

      result.docs.reduce((a, e) => {
        if (local_id_array.indexOf(e.local_transaction_no) !== -1) {
          repeat_local_id.push(e.local_transaction_no);
        }
        local_id_array.push(e.local_transaction_no);
        a[e.local_transaction_no] = ++a[e.local_transaction_no] || 0;
        return a;
      }, {});

      const modifiedDocs = await Promise.all(result.docs.map(async doc => {
        const element = {
          ...doc,
          isDuplicate: repeat_local_id.indexOf(doc.local_transaction_no) !== -1,
        };
        // Call the orderChanges function and modify element
        return await this.manageData(element,tenderProfilesStoreService);
      }));

      return modifiedDocs;
    }).catch(function (err) {
      console.log(err);
    });

  }

  // async manageData(element,tenderProfilesStoreService){
  //   const store_detail = JSON.parse(localStorage.getItem('store_detail'));
  //       element.grand_total = Number(element.grand_total);
  //       element.delivery_charge = Number(element.delivery_charge);
  //       element.cash_taken = Number(element.cash_taken);
  //       element.grand_total_display = element.grand_total + element.delivery_charge;
  //       element.cash_taken_display = element.cash_taken + element.delivery_charge;
  //       element.order_detail = [];
  //       // var order_detail_row_data = await this.orderDetailServiceDb.use('GA_DATA', {"order_pid": element.pid }) // Commented to get speed . and added in detail.
  //       // element.order_detail = await this.orderDetailChanges(order_detail_row_data)
  //       let temp = [];
  //       await element.order_type?.forEach(order_type_element => {
  //         if (order_type_element.order_price > 0 || element.grand_total == 0) {
  //           temp.push(order_type_element.order_type_name);
  //         }
  //       });
  //       element.tender_type_name = temp.toString();
  //       if (element.local_delivery_partner_id > 0) {
  //         element.local_delivery_partner_name = store_detail.localDeliveryPartners.find(x => x.id == element.local_delivery_partner_id);
  //       }
  //       //element.selectedTenderProfile = (await this.tenderProfilesStoreServiceDb.use('GA_DATA', { id: element.tender_profile_id }))[0];
  //       element.selectedTenderProfile = tenderProfilesStoreService.find(x => x.id == element.tender_profile_id)??{};

  //       const checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
  //       const created_by = checkinUser.find(x => x.id == element.created_by);
  //       element.created_by_name = created_by ? created_by.name : '';

  //       if (element.customer_address) {
  //         let add_str = '';
  //         if (element.customer_address.house_no) {
  //           add_str += element.customer_address.house_no;
  //         }
  //         if (element.customer_address.society_name) {
  //           add_str += ", ";
  //           add_str += element.customer_address.society_name;
  //         }
  //         if (element.customer_address.city) {
  //           add_str += ", ";
  //           add_str += element.customer_address.city;
  //         }
  //         if (element.customer_address.state) {
  //           add_str += ", ";
  //           add_str += element.customer_address.state;
  //         }
  //         if (element.customer_address.pincode) {
  //           add_str += ", ";
  //           add_str += element.customer_address.pincode;
  //         }
  //         element.customer_address_display = add_str;
  //       }
  //       element.spinner = 0;
  //       return element;
  // }

  async manageData(element, tenderProfilesStoreService) {
 
    const store_detail = JSON.parse(localStorage.getItem('store_detail'));
  
    const propertiesToConvertToNumber = ['grand_total', 'delivery_charge', 'cash_taken'];
    propertiesToConvertToNumber.forEach(prop => {
      element[prop] = Number(element[prop]);
    });
  
    element.grand_total_display = element.grand_total + element.delivery_charge;
    element.cash_taken_display = element.cash_taken + element.delivery_charge;
    /*orderDetailServiceDb nn element.order_detail = []; */
  
    const temp = (element.order_type || []).filter(order_type_element =>
      order_type_element.order_price > 0 || element.grand_total == 0
    ).map(order_type_element => order_type_element.order_type_name);
    element.tender_type_name = temp.join(', ');
  
    if (element.local_delivery_partner_id > 0) {
      element.local_delivery_partner_name = (store_detail.localDeliveryPartners.find(x => x.id == element.local_delivery_partner_id) || {}).name;
    }
  
    element.selectedTenderProfile = tenderProfilesStoreService.find(x => x.id == element.tender_profile_id)??{};
  
    const checkinUser = JSON.parse(localStorage.getItem('checkinUserList'));
    const created_by = checkinUser.find(x => x.id == element.created_by);
    element.created_by_name = created_by ? created_by.name : '';
  
    if (element.customer_address) {
      const address_parts = ['house_no', 'society_name', 'city', 'state', 'pincode']
        .map(prop => element.customer_address[prop])
        .filter(Boolean);
  
      element.customer_address_display = address_parts.join(', ');
    }
  
    element.spinner = 0;
    if(element.table_id > 0){
      element.order_table = this.tablesMainList.find(x => x.id == element.table_id);
    }else{
      element.order_table = {}
    }
    
    return element;
  }
  
  public async getAllForCount(filter) {

    // Query the documents and sort by the "pid" field
    return this.ordersServiceListDB.find({
      selector: filter
      //sort: [{ pid: 'asc' }]
    }).then(result => {
      return result.docs
    }).catch(error => {
      console.log(error);
    });
  }

  public async getById(id: string) {
    const result = await this.ordersServiceListDB.get(id);
    //var tenderProfilesStoreService = await this.tenderProfilesStoreServiceDb.use('GA_DATA', {})
    const sharedDataService = this.injector.get(SharedDataService);
    const tenderProfilesStoreService = JSON.parse(JSON.stringify(await sharedDataService.getTenderProfilesListArray()));
    return await this.manageData(result,tenderProfilesStoreService);
    //return result;
  }


  async getNextSequence(db, sequenceName) {
    try {
      const doc = await db.get(sequenceName);
      doc.current++;
      await db.put(doc);
      return doc.current;
    } catch (error) {
      if (error.status === 404) {
        // The sequence doesn't exist, so create a new one
        await db.put({
          _id: sequenceName,
          current: 1
        });
        return 1;
      } else {
        // There was a conflict while updating the sequence
        // Retry the operation by calling the function again
        return await this.getNextSequence(db, sequenceName);
      }
    }
  }



  async bulkAddOrUpdate(db, docs, fieldName) {
    const existingDocs = await db.find({
      selector: {
        [fieldName]: { $in: docs.map((doc) => doc[fieldName]) },
      },
    });
    const existingDocsMap = new Map();
    existingDocs.docs.forEach((doc) => {
      existingDocsMap.set(doc[fieldName], doc);
    });
    const docsWithIds = [];
    for (const doc of docs) {
      const existingDoc = existingDocsMap.get(doc[fieldName]);
      const datatoinsert: any = {};
      if (existingDoc) {
        // Update existing doc
        datatoinsert._id = existingDoc._id;
        datatoinsert._rev = existingDoc._rev;
        datatoinsert.pid = existingDoc.pid;
      } else {
        // Add new doc
        const sequence = await this.getNextSequence(this.sequenceDb, this.sequenceDocId);
        datatoinsert.pid = sequence;
      }
      //console.log('doc.local_insert_order_number',doc.local_insert_order_number);
      if(!doc.local_insert_order_number) {
        var randomNumber = Math.floor(10000 + Math.random() * 90000);
        var timestamp = new Date().getTime();
        doc.local_insert_order_number = Number(randomNumber+ '' + timestamp)
      }
      //console.log('doc.local_insert_order_number',doc.local_insert_order_number);
      datatoinsert.local_insert_order_number = doc.local_insert_order_number;
      datatoinsert.local_transaction_no = doc.local_transaction_no;
      datatoinsert.id = doc.id;
      datatoinsert.store_order_id = doc.store_order_id;
      datatoinsert.terminal_order_id = doc.terminal_order_id;
      datatoinsert.store_order_sequence_id = doc.store_order_sequence_id;
      datatoinsert.business_id = doc.business_id;
      datatoinsert.state_id = doc.state_id;
      datatoinsert.store_id = doc.store_id;
      datatoinsert.terminal_id = doc.terminal_id;
      datatoinsert.reference_no = doc.reference_no;
      datatoinsert.rider_id = doc.rider_id;
      datatoinsert.rider_name = doc.rider_name;
      datatoinsert.local_delivery_partner_id = doc.local_delivery_partner_id;
      datatoinsert.local_delivery_partner_rider_id = doc.local_delivery_partner_rider_id;
      datatoinsert.rider_accept_reject_delay_status = doc.rider_accept_reject_delay_status;
      datatoinsert.customer_id = doc.customer_id;
      var table_source = 0;
      if (!doc.table_source) {
        if (doc.table_id > 0) {
          table_source = 1
        } else {
          table_source = 0
        }
      } else {
        table_source = doc.table_source
      }

      datatoinsert.table_source = table_source;
      datatoinsert.table_id = doc.table_id;
      datatoinsert.customer_address = doc.customer_address;
      datatoinsert.adujusted_order_id = doc.adujusted_order_id;
      datatoinsert.cash_change = doc.cash_change;
      datatoinsert.cash_taken = doc.cash_taken;
      datatoinsert.round_off = doc.round_off;
      datatoinsert.total_item = doc.total_item;
      datatoinsert.total_item_qty = doc.total_item_qty;
      datatoinsert.total_discount = doc.total_discount;
      datatoinsert.total_tax = doc.total_tax;
      datatoinsert.total_price = doc.total_price;
      datatoinsert.grand_total = doc.grand_total;
      datatoinsert.tax_id = doc.tax_id;
      datatoinsert.tax_rate = doc.tax_rate;
      datatoinsert.tax_type = doc.tax_type;
      datatoinsert.discount_id = doc.discount_id;
      datatoinsert.discount_rate = doc.discount_rate;
      datatoinsert.discount_type = doc.discount_type;
      datatoinsert.coupon_id = doc.coupon_id;
      datatoinsert.customer_name = doc.customer_name;
      datatoinsert.customer_phone = doc.customer_phone;
      datatoinsert.coupon_discount = doc.coupon_discount;
      datatoinsert.coupon_type = doc.coupon_type;
      datatoinsert.shipping_cost = doc.shipping_cost;
      datatoinsert.order_status = doc.order_status;
      datatoinsert.cancellation_reason = doc.cancellation_reason;
      datatoinsert.payment_status = doc.payment_status;
      datatoinsert.payment_response = doc.payment_response;
      datatoinsert.paid_amount = doc.paid_amount;
      datatoinsert.sale_note = doc.sale_note;
      datatoinsert.staff_note = doc.staff_note;
      datatoinsert.tender_profile_id = doc.tender_profile_id;
      datatoinsert.tender_profile_name = doc.tender_profile_name;
      datatoinsert.price_override_status = doc.price_override_status;
      datatoinsert.delivery_type = doc.delivery_type;
      datatoinsert.delivery_charge = doc.delivery_charge;
      datatoinsert.delivery_km = doc.delivery_km;
      datatoinsert.device_token = doc.device_token;
      datatoinsert.device_type = doc.device_type;
      datatoinsert.is_kds_order = doc.is_kds_order;
      datatoinsert.created_by = doc.created_by;
      datatoinsert.updated_by = doc.updated_by;
      datatoinsert.created_at = doc.created_at;
      datatoinsert.updated_at = doc.updated_at;
      datatoinsert.licence_plate = doc.licence_plate;
      datatoinsert.coupon = doc.coupon;
      datatoinsert.print_items = doc.print_items??0;   // for need to print kot or not if no item added update.
      datatoinsert.order_source = doc.order_source;
      datatoinsert.app_type = doc.app_type;
      datatoinsert.transaction_id = doc.transaction_id;
      datatoinsert.transaction_status = doc.transaction_status;
      datatoinsert.business_payments_id = doc.business_payments_id;
      datatoinsert.business_payments_name = doc.business_payments_name;
      datatoinsert.delivery_partner_id = doc.delivery_partner_id;
      datatoinsert.delivery_partner_order_id = doc.delivery_partner_order_id;
      datatoinsert.external_order_id = doc.external_order_id;
      datatoinsert.order_pickup_datetime = doc.order_pickup_datetime;
      datatoinsert.order_completed_datetime = doc.order_completed_datetime;
      datatoinsert.is_checkout_pending = doc.is_checkout_pending; // THIS IS ONLY FOR UPDATE & PRINT ORDER
      datatoinsert.created_at_formated = doc.created_at_formated;
      datatoinsert.updated_at_formated = doc.updated_at_formated;
      datatoinsert.delivery_partner_order_details = doc.delivery_partner_order_details;
      datatoinsert.tender_profile_type = doc.tender_profile_type;
      datatoinsert.tender_profile_image = doc.tender_profile_image;
      datatoinsert.table_name = doc.table_name;
      datatoinsert.numberofguest = doc.numberofguest;
      datatoinsert.order_customer = doc.order_customer;
      datatoinsert.order_type = doc.order_type;
      datatoinsert.order_rider = doc.order_rider;
      datatoinsert.order_created_user = doc.order_created_user;
      datatoinsert.order_table = doc.order_table;
      datatoinsert.isSync = doc.isSync;
      datatoinsert.order_detail = doc.order_detail;
      datatoinsert.afterSyncNeedToPrintKot = doc.afterSyncNeedToPrintKot??0;
      datatoinsert.is_kds_item_updated = doc.is_kds_item_updated??0;
      datatoinsert.useOrderPlaceOrUpdate = doc.useOrderPlaceOrUpdate ?? 0;  // which API need to call , 1 =orderupdate or 0= orderplace
      docsWithIds.push(datatoinsert);
    }
    try {
      // Bulk add documents to the database
      const result = await db.bulkDocs(docsWithIds);
      // const sharedDataService = this.injector.get(SharedDataService);
      // sharedDataService.getOrderListArray(1);
      return result;
    } catch (error) {
      console.log('Error adding documents:', error);
    }
  }

  public async delete(id: string): Promise<void> {
    let doc;
    // Fetch the document to get the current _rev value
    try {
      doc = await this.ordersServiceListDB.get(id);
      // Delete the document using the current _id and _rev values
      try {
        await this.ordersServiceListDB.remove(id, doc._rev);
        // const sharedDataService = this.injector.get(SharedDataService);
        // sharedDataService.getOrderListArray(1);
        //console.log('Document deleted successfully!');
      } catch (err) {
        console.error('Error deleting document:', err);
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      return;
    }
  }



  public async dropAdd(data) {

    return await this.ordersServiceListDB.destroy().then(async () => {
      // Create a new database
      this.ordersServiceListDB = new PouchDB('ordersServiceList');
      return await this.bulkAddOrUpdate(this.ordersServiceListDB, data, "id");
    }).then(() => {
      return 0;
    }).catch((error) => {
      console.error('Error:', error);
      return 0;
    });
  }



}
