import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { appConfig } from '../app.config';
import { AuthenticationService } from '../authservices/authentication.service';

@Injectable()
export class TerminalGuard implements CanActivate {

    constructor(public auth: AuthenticationService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (JSON.parse(localStorage.getItem("isTerminalLogin")) == true || JSON.parse(localStorage.getItem("isTerminalLogin"))) {
            return true;
        }
        this.router.navigate(['login']);
        return false;
    }

}
