<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <ng-template #clockModel>
    <div class="modal-header">
      <h4 class="modal-title">{{'Clock In' | translate}}</h4>
      <button type="button" class="close" (click)="modalRef.hide()"><img alt="" src="assets/images/close.png"></button>
    </div>
    <div class="modal-body modal-clock-in-out">
      <div class="row">
        <div class="col-sm-6">
          <form (submit)="clockInSubmit();" #clockinForm="ngForm">

            <div class="form-group">

              <input *ngIf="isPlatform == true && is_login_with_pin == 0" inputmode="none" type="text"
                [(ngModel)]="user.newusernameclockin" (focus)="onfocus('newusernameclockin')" name="newusernameclockin"
                #newusernameclockin="ngModel" class="form-control"
                [ngClass]="textboxname == 'newusernameclockin' ? 'textboxselected' : ''" autocomplete="off"
                placeholder="{{'Username' | translate}}" required>

              <input *ngIf="isPlatform != true && is_login_with_pin == 0" inputmode="none" type="number"
                [(ngModel)]="user.newusernameclockin" (focus)="onfocus('newusernameclockin')" name="newusernameclockin"
                #newusernameclockin="ngModel" class="form-control"
                [ngClass]="textboxname == 'newusernameclockin' ? 'textboxselected' : ''" autocomplete="off"
                placeholder="{{'Username' | translate}}" required>
            </div>

            <div class="form-group">
              <input *ngIf="is_login_with_pin == 0" inputmode="none" type="text" autocomplete="off"
                style="-webkit-text-security: disc;"
                [ngClass]="textboxname == 'newuserpasswordclockin' ? 'textboxselected' : ''"
                [(ngModel)]="user.newuserpasswordclockin" name="newuserpasswordclockin"
                (focus)="onfocus('newuserpasswordclockin')" #newuserpasswordclockin="ngModel" class="form-control"
                placeholder="{{'Password' | translate}}" required>

              <input *ngIf="is_login_with_pin == 1" inputmode="none" type="password" autocomplete="off"
                [ngClass]="textboxname == 'newusernameclockin' ? 'textboxselected' : ''"
                [(ngModel)]="user.newusernameclockin" name="newusernameclockin" (focus)="onfocus('newusernameclockin')"
                #newusernameclockin="ngModel" class="form-control" placeholder="{{'Pin' | translate}}" required>
            </div>

            <div class="form-group">
              <button class="btn btn-block btn-success" type="submit" [disabled]="!clockinForm.form.valid">{{'Clock In'
                | translate}}</button>
            </div>
          </form>
        </div>
        <div class="col-sm-6">
          <div class="calculator">

            <div class="calculator-keys">

              <button type="button" (click)="getNumber('1')" value="1">1</button>
              <button type="button" (click)="getNumber('2')" value="2">2</button>
              <button type="button" (click)="getNumber('3')" value="3">3</button>


              <button type="button" (click)="getNumber('4')" value="4">4</button>
              <button type="button" (click)="getNumber('5')" value="5">5</button>
              <button type="button" (click)="getNumber('6')" value="6">6</button>


              <button type="button" (click)="getNumber('7')" value="7">7</button>
              <button type="button" (click)="getNumber('8')" value="8">8</button>
              <button type="button" (click)="getNumber('9')" value="9">9</button>
              <span></span>
              <button type="button" (click)="getNumber('0')" value="0">0</button>
              <button type="button" (click)="remove()" class="all-clear"><img src="assets/images/icon-backspace.png"
                  class="img-fluid" alt=""></button>
            </div>
          </div>
        </div>
      </div>

    </div>

  </ng-template>
</ion-app>
<ngx-spinner name="syncspinner" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <h6 style="color: #ffffff; margin:0">{{'Please wait POS is syncing...' | translate}}</h6>
</ngx-spinner>
<ngx-spinner name="spinner" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <h6 style="color: #ffffff; margin:0">{{'Loading...' | translate}}</h6>
</ngx-spinner>
<ngx-spinner name="endofdayspinner" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <h6 style="color: #ffffff; margin:0">{{'Please wait! Report is under process...' | translate}}</h6>
</ngx-spinner>
<ngx-spinner name="endofdayspinnerafter" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
  <h6 style="color: #ffffff; margin:0">{{'Your business day is being closed...' | translate}}</h6>
</ngx-spinner>