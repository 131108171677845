import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TerminalGuard } from './guards/terminal.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./system/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./system/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./pages/attendance/attendance.module').then(m => m.AttendancePageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'start-of-day',
    loadChildren: () => import('./pages/start-of-day/start-of-day.module').then(m => m.StartOfDayPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'one-minute-cash-in',
    loadChildren: () => import('./pages/one-minute-cash-in/one-minute-cash-in.module').then(m => m.OneMinuteCashInPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'pay-later',
    loadChildren: () => import('./pages/pay-later/pay-later.module').then(m => m.PayLaterPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'pos-dashboard',
    loadChildren: () => import('./pages/pos-dashboard/pos-dashboard.module').then(m => m.PosDashboardPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'pos',
    loadChildren: () => import('./pages/pos/pos.module').then(m => m.PosPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'cashout',
    loadChildren: () => import('./pages/cashout/cashout.module').then(m => m.CashoutPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'paidout',
    loadChildren: () => import('./pages/paidout/paidout.module').then(m => m.PaidoutPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'tables',
    loadChildren: () => import('./pages/tables/tables.module').then(m => m.TablesPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'end-of-day',
    loadChildren: () => import('./pages/end-of-day/end-of-day.module').then(m => m.EndOfDayPageModule),
    canActivate: [TerminalGuard]
  },
  {
    path: 'transaction-lookup',
    loadChildren: () => import('./pages/transaction-lookup/transaction-lookup.module').then(m => m.TransactionLookupPageModule)
  },
  {
    path: 'manager-desk',
    loadChildren: () => import('./pages/manager-desk/manager-desk.module').then(m => m.ManagerDeskPageModule)
  },
  {
    path: 'customer-lookup',
    loadChildren: () => import('./pages/customer-lookup/customer-lookup.module').then(m => m.CustomerLookupPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
