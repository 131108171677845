import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appConfig } from '../app.config';
import { map } from 'rxjs/operators'
import { PassingDataService } from '../services/passing-data.service';
import { Router } from '@angular/router';
import { PushNotifications } from '@capacitor/push-notifications';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private passData: PassingDataService, private _router: Router, @Inject(DOCUMENT) private document: Document) { }

  storeLogin(data) {
    return this.http.post<any>(appConfig.apiUrl + '/storeterminallogin', data)
      .pipe(map(res => {
        return res;
      }));
  }

  login(data) {

    return this.http.post<any>(appConfig.apiUrl + '/login', data).pipe(map(res => {
      var user = res.data;
      // login successful if there's a jwt token in the response
      if (user && user.user_token) {
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('token', JSON.stringify(user.user_token));
        localStorage.setItem("defaultUser", user.username);
        this.passData.defaultUserName(user.username);
      }
      return res;
    }));
  }

  async logout() {
    try {
      await PushNotifications.unregister()
    } catch (error) {
      console.log("Error", error)
    }
    localStorage.clear()
    window.location.reload();
  }

  quit() {
    localStorage.removeItem('isLogin')
    localStorage.removeItem('isStartofDay')
    var store_detail = JSON.parse(localStorage.getItem('store_detail'))
    if (store_detail) {
      localStorage.setItem('language', store_detail.primary_language_name)
    } else {
      localStorage.setItem('language', 'english')
    }
    localStorage.setItem('isTerminalLogin', JSON.stringify(false));
    this._router.navigate(['login'])
    let htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    htmlTag.dir = 'ltr';
  }

}
